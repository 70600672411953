.container {
  height: auto;
  margin: 20px;
  padding-right: 30px;
  background: #ffffff;
}
.errormessage {
  font: normal normal 14px/16px Arial, sans-serif;
  letter-spacing: 0.1px;
  color: #fd0000;
  opacity: 1;
  position: relative;
  top: 3px;
  display: block;
}
.errMessageScrnOne {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #fd0000;
  margin-top: 2px;
}
.errMessageScrnOne fieldset {
  border-radius: 6px;
  border: 1.6px solid #fd0000;
}

.radioLabel {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #444444 !important;
}
.attempt {
  text-align: center;
  font-size: 14px bold;
  color: #661c69;
  padding: 20px 0;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.welcomeTextR {
  font-family: 'TTNormsProBold', sans-serif !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 32px !important;
  line-height: 40px !important;
  color: #000000 !important;
}

.textError {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #fd0000 !important;
  margin-bottom: 7px !important;
}

.welcomeTextLineTwoR {
  font-family: 'TTNormsProBold', sans-serif !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 30px !important;
  line-height: 40px !important;
  color: #000000 !important;
}

.containerCenter {
  display: flex;
  justify-content: end;
  background-image: url('../../images/ETBHomePage.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: 848px 612px;
}
.horizontalLineBreak {
  border: 1px solid #c5cee0;
  margin-top: 15px;
  margin-bottom: 20px;
  background-color: #c5cee0;
}

.titleLogin {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #000000 !important;
}

.title {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 24px !important;
  color: #000000 !important;
}
.title:first-of-type {
  display: inline;
}
.label_adj {
  margin-bottom: 15px;
}
.label {
  margin-top: 8px;
}
.btnAlign {
  display: flex;
  justify-content: space-between;
}
.termsCond {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin-top: 15px;
  margin-bottom: 35px;
}
.link {
  color: #661c69;
  text-decoration: underline;
}
.secGrid {
  max-width: 453px;
}
.imgGrid {
  display: contents;
}
.outerContainer {
  background: #ffffff;
  border: 1px solid #d0d0d0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.21);
  border-radius: 6px;
  max-width: 960px;
  margin: 50px 0;
}
.fieldContainer {
  margin: 30px;
}
.imgStyle {
  max-width: 488px;
  max-height: 553px;
  background: #f3f3f3;
}
.forgotAcctBox {
  display: inline-block;
}
.radioColor {
  color: #661c69 !important;
}
.boxMarginTop {
  margin-top: 20px;
}
.infoIcon {
  margin-top: 7.33px;
  margin-right: 5.33px;
  display: inline-flex;
}
.linkAccount {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #661c69;
  position: relative;
  top: -3px;
  text-decoration: none;
  text-transform: none;
  padding-left: 0;
}
.linkAccount:hover {
  text-decoration: underline;
}
@media screen and (max-width: 600px) {
  .forgotAcctBox {
    display: inline-block;
    margin-bottom: 20px;
  }
  .container {
    height: auto;
    margin: 10px;
    padding: 0 18px;
    padding-top: 30px;
    background: #ffffff;
  }
  .containerCenter {
    display: flex;
    justify-content: center;
  }
  .welcomeTextR {
    font-family: 'TTNormsProBold', sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 22px !important;
    line-height: 24px !important;
    color: #000000 !important;
  }

  .welcomeTextLineTwoR {
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 26px !important;
    line-height: 32px !important;
    color: #000000 !important;
  }
  .boxMarginTop {
    margin-top: 35px;
  }
}
@media only screen and (min-width: 960px) {
  .horizontalLineBreak {
    width: 116%;
    position: relative;
    right: 31px;
  }
}
@media only screen and (max-width: 1050px) {
  .imgGrid {
    max-width: 460px;
    max-height: 100%;
  }
  .outerContainer {
    background: #ffffff;
    border: 1px solid #d0d0d0;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.21);
    border-radius: 6px;
    max-width: 920px;
  }
  .imgStyle {
    max-width: 460px;
    max-height: 533px;
  }
  .marginTop {
    margin-top: 10px;
  }
}
